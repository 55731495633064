import { post } from '@/utils/request';
// 导出
// /store/goods.EditDocument
const exportReq = (data) => post({
  url: '/store/goods.EditDocument/listExport',
  responseType: 'blob',
  data: {
    ...data,
  },
})

// 列表
const listReq = (cond) => post({
  url: '/store/goods.EditDocument/list',
  data: {
    ...cond,
  },
});

// 打印修改单数据
const getEditGoodsPrintReq = (params) => post({
  url: '/store/goods.EditDocument/editGoodsPrint',
  data: {
    ...params,
  },
});

// 反确认删除
const resetDeleteReq = (params) => post({
  url: '/store/goods.EditDocument/resetDelete',
  data: {
    ...params,
  },
});

// 审核不通过删除
const handleDeleReq = (params) => post({
  url: '/store/goods.EditDocument/delete',
  data: {
    ...params,
  },
});


export {
  exportReq,
  listReq,
  getEditGoodsPrintReq,
  resetDeleteReq,
  handleDeleReq,
};
