import { post } from '@/utils/request';

// 调拨详情
const detailReq = (edit_id) => post({
  url: '/store/goods.EditDocument/detail',
  data: {
    edit_id,
  },
});

// 反确认-修改单详情
const resetDetailGoodsListReq = (params) => post({
  url: '/store/goods.EditDocument/resetDetailGoodsList',
  data: {
    ...params,
  },
});

// 反确认-修改单提交
const resetReq = (params) => post({
  url: '/store/goods.EditDocument/reset',
  data: {
    ...params,
  },
});

// 调拨详情--商品列表
const detailGoodsListReq = ({ edit_id, page, page_num }) => post({
  url: '/store/goods.EditDocument/detailGoodsList',
  data: {
    edit_id,
    page,
    page_num,
  },
});

// 操作日志
const getLogListReq = (params) => post({
  url: '/store/goods.EditDocument/getLogList',
  data: {
    ...params,
  },
});

export {
  detailReq,
  detailGoodsListReq,
  getLogListReq,
  resetDetailGoodsListReq,
  resetReq,
};
