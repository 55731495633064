import { post } from '@/utils/request';

const createReq = (cond) => post({
  url: '/store/goods.EditDocument/create',
  data: {
    ...cond,
  },
});

export {
  createReq,
};
