<template>
  <div class='content'  style="background: #f6f7fb;">
    <div v-show="!addVisiable && !detailVisiable && !examineVisiable">
      <div class='card' style='display: flex;'>
        <div class="left">
          <el-button type='primary' @click='dialogVisible = true' icon='el-icon-plus'>新增修改单</el-button>
          <el-button type='primary' plain @click='handleExport' icon='el-icon-upload2' :loading="loading3">导出</el-button>
        </div>
        <div class='line'></div>
        <div class="middle">
          <Cond @onCond="handleCond" function_code="edit" :mode="mode" />
        </div>
        <div class="right" style="display: flex;">
          <TableConf function_code="edit" @onRowList="handleRowList"  :mode="mode" />
          <PrintConf function_code="editGoodsPrint" @onRowList="handlePrintRow" />
          <el-button @click="changeMode" style="margin-left: 10px; height: 40px;">切换版式：{{mode === 1? '货品模式' : '单号模式'}}</el-button>
        </div>
      </div>
      <div class="total">
          <div class="total-right">
            <div class="total-right-item">总件数 <span>{{ data.goods_total || 0}}</span></div>
            <div class="total-right-item">总净金重 <span>{{ data.gold_weight || '0.00'}}</span></div>
          </div>
      </div>
      <div class='table' v-if="rowList.length" style="border: 1px solid #ddd; border-radius: 6px; overflow: hidden;">
        <el-table :data='data.list' stripe v-loading="loading" height="660" @row-dblclick="handleDalclick">
          <template v-for='(item, index) in rowList'>
            <el-table-column
              v-if="item.filed_status"
              align='center'
              :key='index'
              :prop="item.field_alias"
              :label='item.field_text'
              :width="item.t_width"
              :filters="item.filed_status"
              :filter-method="filterHandler"
            >
              <template slot-scope="scope">
                <template v-if="item.field_alias === 'goods_pic'">
                  <img
                    :src="scope.row.goods_pic"
                    v-if="scope.row.goods_pic"
                    class="image-slot"
                  />
                  <div slot="error" class="image-slot" v-else>
                    <img src="../../../../assets/images/no_img.png" />
                  </div>
                </template>
                <template v-else>
                  {{scope.row[item.field_alias] ? scope.row[item.field_alias]: '--'}}
                </template>
                <!-- <template v-if="item.field_alias !== 'warehouse_status'">
                  {{scope.row[item.field_alias] ? scope.row[item.field_alias]: '--'}}
                </template>
                <template v-else>
                  <el-switch
                    v-model='scope.row.warehouse_status'
                    :active-value="10"
                    :inactive-value="20"
                    :disabled="scope.row.hierarchy_id == 1"
                    @change="handleSwitch(scope.row)"
                  >
                  </el-switch>
                </template> -->
              </template>
            </el-table-column>
            <el-table-column
              v-else
              align='center'
              :key='index'
              :prop="item.field_alias"
              :label='item.field_text'
              :width="item.t_width"
            >
              <template slot-scope="scope">
                <template v-if="item.field_alias === 'goods_pic'">
                  <img
                    :src="scope.row.goods_pic"
                    v-if="scope.row.goods_pic"
                    class="image-slot"
                  />
                  <div slot="error" class="image-slot" v-else>
                    <img src="../../../../assets/images/no_img.png" />
                  </div>
                </template>
                <template v-else>
                  {{scope.row[item.field_alias] ? scope.row[item.field_alias]: '--'}}
                </template>
                <!-- <template v-if="item.field_alias !== 'warehouse_status'">
                  {{scope.row[item.field_alias] ? scope.row[item.field_alias]: '--'}}
                </template>
                <template v-else>
                  <el-switch
                    v-model='scope.row.warehouse_status'
                    :active-value="10"
                    :inactive-value="20"
                    :disabled="scope.row.hierarchy_id == 1"
                    @change="handleSwitch(scope.row)"
                  >
                  </el-switch>
                </template> -->
              </template>
            </el-table-column>
          </template>
          <el-table-column align='center' fixed='right' field_alias='operation' label='操作' width="180">
            <template slot-scope="scope">
              <div class="options">
                <el-select
                  v-model="scope.row.select"
                  placeholder="请选择"
                  @change="handleChange($event, scope.row)"
                >
                  <el-option label="详情" :value="1"></el-option>
                  <el-option label="审核" :value="2" v-if="scope.row.edit_status === 10"></el-option>
                  <el-option label="查看失败原因" :value="3" v-if="scope.row.edit_status === 30"></el-option>
                  <el-option label="编辑" :value="4" v-if="scope.row.edit_status === 30  || noSockIds.includes(scope.row.edit_id) === true"></el-option>
                  <el-option label="打印" :value="5"></el-option>
                  <el-option label="打印标签" :value="6" v-if="scope.row.edit_status === 20"></el-option>
                  <el-option
                        label="解锁"
                        v-if="
                          scope.row.edit_status === 20 &&
                          noSockIds.includes(scope.row.edit_id) === false
                        "
                        :value="20"
                      ></el-option>
                      <!-- <el-option
                        label="锁定"
                        :value="21"
                        v-if="
                          noSockIds.includes(scope.row.allot_id) === true
                        "
                      ></el-option> -->
                      <el-option
                        label="删除"
                        :value="22"
                        v-if="
                            noSockIds.includes(scope.row.edit_id) === true
                        "
                      ></el-option>
                      <el-option
                        label="删除"
                        :value="23"
                        v-if="scope.row.edit_status === 30"
                      ></el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class='pagina'>
          <el-pagination
            background
            :current-page="page"
            @size-change="handleSizeChange"
            layout='total, sizes, prev, pager, next, jumper'
            :total='data.total'
            :page-size='$store.state.pageSizes[0]'
            :page-sizes="$store.state.pageSizes"
            @current-change='handleCurrentChange'
          ></el-pagination>
        </div>
      <LocalPrint :show="false" :printRows="printRows" :printData="printData" :title="title" :dialogVisible="localPrintVisible" @onClose="localPrintVisible = false" />
      <el-dialog
        title="请先选择品类"
        :visible.sync="dialogVisible"
        width="30%"
        :destroy-on-close="true"
        :before-close="handleClose">
        <el-form :model="form" :rules="rules" ref="ruleForm">
          <el-form-item label="品类" label-width="100px" required prop="goods_type_id">
            <!-- <el-select v-model="form.goods_type_id">
              <template v-for="item in select">
                  <el-option :label="item.goods_type_name" :value="item.goods_type_id" :key="item.goods_type_id">
                    <span :style="{marginLeft: item.span * 8 +'px'}">{{item.goods_type_name}}</span>
                  </el-option>
                </template>
            </el-select> -->
            <SelectTree
              id="select"
              :props="props"
              :options="treeData"
              label1="商品分类"
              :clearable="isClearable"
              :accordion="isAccordion"
              @getValue="getValue($event)"
            />
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <el-dialog
      title="失败原因"
      :visible.sync="reasonVisible"
      width="30%"
      :before-close="handleClose">
      <span>
        {{currRow.audit_remark || '--'}}
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleClose">确 定</el-button>
      </span>
    </el-dialog>
    <Add
      v-if="addVisiable"
      :allot_state="1"
      :edit_id="currRow.edit_id"
      :goods_type_id="form.goods_type_id"
      @onChangeValue="handleChangeValue"
      :text="text"
      @hide="handleHideAdd"
      @getList="getList"
      :row="currRow"
    />
    <Detail
      v-if="detailVisiable"
      :goods_type_id="currRow.goods_type_id"
      :propObj="propObj"
      @hide="handleHideDetail"
      :showPrint="true"
      :title="title"
    />
    <Examine
      v-if="examineVisiable"
      :goods_type_id="currRow.goods_type_id"
      :edit_id="currRow.edit_id"
      @hide="handleHideExamine"
      @getList="getList"
    />
    <el-dialog
      :visible.sync="printVisible"
      :before-close="beforeClose"
      title="打印标签"
      :destroy-on-close="true"
    >
      <Print :type="50" :order_id="currRow.edit_id" v-if="printVisible"  print_bill_type="EditDocument"  />
    </el-dialog>
  </div>
</template>

<script>
import { listReq, getEditGoodsPrintReq, resetDeleteReq, exportReq, handleDeleReq } from '@/api/goods/goodsWarehousing/modifyList/list';
import numeral from 'numeral';
import { getIsLock } from '@/api/monthSettle';
import {
  getListReq as getTypeListReq
} from '@/api/goods/goodsConfig/classify';
import moment from 'moment';
import Cond from '@/components/cond/Index.vue';
import TableConf from '@/components/tableConf/Index.vue';
import Add from "./Add.vue";
import Detail from "./Detail.vue";
import Examine from "./Examine.vue";
import PrintConf from "@/components/printConf/Index.vue";
import LocalPrint from "@/components/localPrint/Index.vue";
import Print from "@/components/print/Index.vue";

export default {
  data() {
    return {
      printVisible: false,
      noSockIds: [],
      title: '修改单',
      localPrintVisible: false,
      printData: {},
      printRows: [], // 打印的行
      mode: 1,
      loading3: false,
      text: '',
      isClearable: true, // 可清空（可选）
      isAccordion: true, // 可收起（可选）
      props: {
        value: "goods_type_id",
        label: "goods_type_name",
        children: "children",
      },
      treeData: [],
      treeData2: [],
      reasonVisible: false,
      currRow: {},
      select: [],
      propObj: {},
      addVisiable: false,
      detailVisiable: false,
      examineVisiable: false,
      rowList: [
        // {
        //   field_text: '仓库名称',
        //   field_alias: 'warehouse_name',
        // },
        // {
        //   field_text: '所属门店',
        //   field_alias: 'hierarchy_name',
        // },
        // {
        //   field_text: '状态',
        //   field_alias: 'warehouse_status',
        // },
        // {
        //   field_text: '添加人',
        //   field_alias: 'create_user_name',
        // },
        // {
        //   field_text: '添加时间',
        //   field_alias: 'create_time',
        // },
      ],
      loading2: false,
      rules: {
        goods_type_id: [
          { required: true, message: '请选择品类' },
        ],
      },
      merchantList: [],
      loading: false,
      form: {},
      dialogVisible: false,
      total: 0,
      tableVisiable: false,
      cond: {},
      page: 1,
      limit: this.$store.state.pageSizes[0],
      // coordinate: { column: -1, row: -1 },
      data: [],
    };
  },
  components: {
    Cond,
    TableConf,
    Add,
    Detail,
    Examine,
    PrintConf,
    LocalPrint,
    Print,
  },
  created() {
    this.getList();
    this.getTypeList();
    this.getTypeList2();
  },
  mounted() {
    if (this.$route.params.type) {
      if (this.$route.params.type === 'detail') {
        this.propObj = {
          key: 'edit_id',
          value: this.$route.params.key,
        };
        this.currRow.goods_type_id = this.$route.params.goodsType;
        this.detailVisiable = true;
      }
    }
  },
  methods: {
     beforeClose() {
      this.currRow.select = "";
      this.printVisible = false;
    },
    getEditGoodsPrint(cond) {
      getEditGoodsPrintReq({ ...cond })
        .then((res) => {
          if (res.code === 1) {
            this.currRow.select = '';
            this.currRow = {};
            res.data.list.forEach((item, index) => {
              item.index = index + 1;
            });
            res.data.top = [];
            const data = res.data.data;
            const arr1 = [
              { txt: '创建人', value: data.create_store_user_name, width: '24%' },
              { txt: '创建时间', value: data.create_time, width: '24%' },
              { txt: '修改单号', value: data.edit_number, width: '30%' },
            ];
            res.data.bottom = [];
            const arr2 = [
              { txt: '审核人', value: data.audit_store_user_name, width: '24%' },
              { txt: '审核时间', value: data.audit_time_text ? data.audit_time_text : '', width: '30%' },
            ];
            const arr3 = [
              {
                txt: '门店', value: data.hierarchy_name, width: '24%',
              },
            ];
            res.data.top.push(arr1, arr3);
            res.data.bottom.push(arr2);
            // 计算合计
            this.printData = res.data;
            const sum = {
              index: '合计',
              info: [
                { edit_sign: '原始' },
                { edit_sign: '修改' },
                { edit_sign: '差异' },
              ],
            };
            const decimal = {};
            Object.keys(res.data.list[0].goods_info.old_info).forEach((key) => {
              if (!decimal[key]) {
                const arr = String(res.data.list[0].goods_info.old_info[key]).split(".");
                if (arr.length === 2) {
                  decimal[key] = arr[1].length;
                } else {
                  decimal[key] = 0;
                }
              }
            });
            this.printData.list.forEach((item) => {
              if (item.goods_info) {
                if (!item.info) {
                  item.info = [];
                }
                item.info.push({ ...item.goods_info.old_info, edit_sign: '原始' });
                item.info.push({ ...item.goods_info.new_info, edit_sign: '修改' });
              }
              const diff = { edit_sign: '差异' };
              this.printRows.forEach((row) => {
                // 差异值行
                if (row.is_sum && row.is_use) {
                  if (!item.goods_info.new_info[row.field_alias]) {
                    item.goods_info.new_info[row.field_alias] = 0;
                  }
                  if (!item.goods_info.old_info[row.field_alias]) {
                    item.goods_info.old_info[row.field_alias] = 0;
                  }
                  diff[row.field_alias] = Number(item.goods_info.new_info[row.field_alias]) - Number(item.goods_info.old_info[row.field_alias]);
                  // const arr = String(item.goods_info.old_info[row.field_alias]).split(".");
                  // if (arr.length === 2) {
                  //   diff[row.field_alias] = diff[row.field_alias].toFixed(arr[1].length);
                  // }
                  if (diff[row.field_alias] % 1 !== 0 && diff[row.field_alias]) {
                    diff[row.field_alias] = Number(numeral(diff[row.field_alias]).format('0.0000')) + 0;
                  }

                  // 合计
                  item.info.forEach((infoData, infoIndex) => {
                    if (!sum.info[infoIndex][row.field_alias]) {
                      sum.info[infoIndex][row.field_alias] = 0;
                    }
                    sum.info[infoIndex][row.field_alias] += Number(infoData[row.field_alias]);
                  });
                }
              });
              item.info.push(diff);
            });
            // 需要保留多少位小数 以及 总差异
            Object.keys(sum.info[0]).forEach((key) => {
              if (key !== 'edit_sign') {
                sum.info[2][key] = Number(sum.info[1][key]) - Number(sum.info[0][key]);
                if (sum.info[0][key] % 1 !== 0 && sum.info[0][key]) {
                  sum.info[0][key] = Number(numeral(sum.info[0][key]).format('0.0000')) + 0;
                }
                if (sum.info[1][key] % 1 !== 0 && sum.info[1][key]) {
                  sum.info[1][key] = Number(numeral(sum.info[1][key]).format('0.0000')) + 0;
                }
                if (sum.info[2][key] % 1 !== 0 && sum.info[2][key]) {
                  sum.info[2][key] = Number(numeral(sum.info[2][key]).format('0.0000')) + 0;
                }
                // if (sum.info[0][key] || sum.info[0][key] === 0) {
                //   sum.info[0][key] = sum.info[0][key].toFixed(decimal[key]);
                // }
                // if (sum.info[1][key] || sum.info[1][key] === 0) {
                //   sum.info[1][key] = sum.info[1][key].toFixed(decimal[key]);
                // }
                // if (sum.info[2][key] || sum.info[2][key] === 0) {
                //   sum.info[2][key] = sum.info[2][key].toFixed(decimal[key]);
                // }
              }
            });
            this.printData.list.push(sum);
            this.localPrintVisible = true;
          }
        })
        .catch(() => {
          this.currRow.select = '';
        });
    },
    handlePrintRow(rows) {
      this.printRows = rows;
    },
    handleChangeValue(value) {
      const form = { ...this.form };
      form.goods_type_id = value;
      this.form = form;
    },
    changeMode() {
      this.rowList = [];
      this.mode === 1 ? this.mode = 2: this.mode = 1;
      this.page = 1;
      this.getList();
    },
    handleDalclick(row) {
      this.currRow = row;
      this.propObj = {
        key: 'edit_id',
        value: row.edit_id,
      };
      this.detailVisiable = true;
      this.cancelTable();
    },
    // 取值
    getValue(value) {
      const form = { ...this.form };
      form.goods_type_id = value;
      this.form = form;
      this.$nextTick(() => {
        const dom = document.getElementById('select').getElementsByTagName('input')[0];
        this.text = dom.value;
      });
    },
    handleSizeChange(limit) {
      this.page = 1;
      this.limit = limit;
      this.getList();
    },
    handleHideDetail() {
      console.log(this.page);
      this.detailVisiable = false;
      this.currRow = {};
    },
    handleHideExamine() {
      this.form = {};
      this.currRow = {};
      this.examineVisiable = false;
    },
    handleHideAdd() {
      this.form = {};
      this.currRow = {};
      this.addVisiable = false;
    },
    handleArr(arr) {
      const newArr = [];
      function poling(arr, span) {
        arr.forEach((item) => {
          if (span || span === 0) {
            item.span = span + 1;
            item.goods_type_name = '├─' + item.goods_type_name;
          } else {
            item.span = 0;
          }
          newArr.push(item);
          if (item.children) {
            poling(item.children, item.span);
          }
        });
      }
      // 深复制
      const aArr = JSON.parse(JSON.stringify(arr));
      poling(aArr);
      return newArr;
    },
    getTypeList() {
      getTypeListReq({
        type: [10],
      })
        .then((res) => {
          if (res.code === 1) {
            this.treeData = res.data;
          }
        });
    },
    getTypeList2() {
      getTypeListReq({
        type: [20],
      })
        .then((res) => {
          if (res.code === 1) {
            this.treeData2 = res.data;
          }
        });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 成功的操作
          this.addVisiable = true;
          this.dialogVisible = false;
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    cancelTable() {
      const coordinate = { ...this.coordinate };
      coordinate.row = -1;
      coordinate.column = -1;
      this.coordinate = coordinate;
      this.tableVisiable = false;
      this.tableVisiable2 = false;
      this.currRow.select = '';
    },
    filterHandler(value, row, column) {
      let { property } = column;
      if (property === 'edit_status_text') {
        property = 'edit_status';
      }
      return row[property] === value;
    },
    // 处理表格数据
    handleRowList(rowList) {
      this.rowList = rowList;
    },
    handleCond(cond) {
      this.page = 1;
      this.cond = { ...cond };
      this.getList();
    },
    getList() {
      this.loading = true;
      listReq({ ...this.cond, page: this.page, page_num: this.limit, mode: this.mode })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            // res.data.list.forEach((item) => {
            //   if (item.create_time) {
            //     item.create_time = moment(item.create_time * 1000).format('yyyy-MM-DD hh:mm:ss');
            //   }
            // });
            this.data = res.data;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleClose () {
      this.dialogVisible = false;
      this.reasonVisible = false;
      this.form = {};
      this.cancelTable();
    },
    fetchData() {
      this.getList();
    },
    /*
     *  导出
     */
    handleExport() {
      this.loading3 = true;
      exportReq({ ...this.cond, page: this.page, limit: this.limit, export: 2 })
        .then((res) => {
          this.loading3 = false;
          let blob = new Blob([res], { type: "application/vnd.ms-excel" });
          const a = document.createElement("a");
          a.setAttribute("download", `修改单列表.xlsx`);
          a.href = URL.createObjectURL(blob);
          a.click();
        })
        .catch(() => {
          this.loading3 = false;
        });
    },
    /*
     *  新增入库
     */
    handleAdd() {
      this.addVisiable = true;
    },
    /*
     *  检索
     */
    handleChange(val, row) {
      this.currRow = row;
      this.propObj = {
        key: 'edit_id',
        value: row.edit_id,
      };
      if (val === 1) {
        this.detailVisiable = true;
        this.cancelTable();
      }
      if (val === 2) {
        this.examineVisiable = true;
        this.cancelTable();
      }
      if (val === 3) {
        this.reasonVisible = true;
      }
      if (val === 4) {
        const form = { ...this.form };
        form.goods_type_id = row.goods_type_id;
        this.form = form;
        this.addVisiable = true;
        this.cancelTable();
      }
      if (val === 5) {
        this.getEditGoodsPrint({
          edit_id: row.edit_id,
        });
      }
      // 打印标签
      if (val === 6) {
        this.printVisible = true;
      }
      if (val === 20) {
        // 此处请求接口待定
        getIsLock({
          source: 'edit',
          primary_key_id: row.edit_id,
        })
          .then((res) => {
            this.currRow = {};
            if (res.code === 1) {
              this.$message.success('解锁成功');
              const form = { ...this.form };
              form.goods_type_id = row.goods_type_id;
              this.form = form;
              // this.addVisiable = true;
              this.cancelTable();
              this.noSockIds.push(row.edit_id);
              row.select = '';
            }
          })
          .catch(() => {
            row.select = '';
            this.currRow = {};
          });
      }
      // 反确认删除
      if (val === 22) {
        this.$confirm('确定要删除该修改单？删除后将无法恢复，货品信息将恢复到修改前。', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            row.select = '';
            resetDeleteReq({
              edit_id: row.edit_id,
            })
              .then((res) => {
                this.currRow = {};
                if (res.code === 1) {
                  this.getList();
                }
              });
          })
          .catch(() => {
            row.select = '';
            this.currRow = {};
          })
      }
      // 不通过删除
      if (val === 23) {
        this.$confirm('确定要删除该修改单？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            row.select = '';
            handleDeleReq({
              edit_id: row.edit_id,
            })
              .then((res) => {
                this.currRow = {};
                if (res.code === 1) {
                  this.getList();
                }
              });
          })
          .catch(() => {
            row.select = '';
            this.currRow = {};
          })
      }
    },
    /*
     *  分页操作
     */
    handleCurrentChange(page) {
      this.page = page;
      this.getList();
    },
    /*
     *  关闭列表配置弹窗
     */
    // cancelTable() {
    //   const coordinate = { ...this.coordinate };
    //   coordinate.row = -1;
    //   coordinate.column = -1;
    //   this.coordinate = coordinate;
    //   this.tableVisiable = false;
    // },
    /*
     *  关闭选择入库类型弹窗
     */
    cancelAdd() {
      this.addVisiable = false;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .image-slot {
  width: 54px;
  height: 54px;
  margin: 5px auto;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 3px;
  }
}
.total {
  display: flex;
  justify-content: space-between;
  line-height: 42px;
  // padding: 0 20px;
  // background: linear-gradient(0deg, #f3f9ff, #ffffff);
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background: white;
  margin-bottom: 10px;
  border-radius: 0px 0px 6px 6px;
  &-left {
    // flex-shrink: 0;
  }
  &-right {
    display: flex;
    flex: 1;
    &-item {
      font-size: 15px;
      // padding: 0 10px;
      width: 15%;
      // border-right: 1px solid #ddd;
      text-align: center;
      color: #909399;
      span {
        font-size: 16px;
        color: #E7541E;
      }
    }
  }
}
.middle {
  flex: 1;
}
</style>
