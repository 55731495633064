<template>
  <div>
    <div class="card">
      <el-page-header @back="goBack" content="修改单审核"> </el-page-header>
    </div>
    <div class="table">
      <el-table
        :data="tableData.list"
        :row-class-name="handleRow"
        :span-method="objectSpanMethod"
        v-loading="loading"
        border
      >
        <template v-for="(item, index) in rowList">
          <el-table-column
            v-if="item.hide ? false : true"
            :key="index"
            :label="item.label"
            :prop="item.prop"
            align="center"
            :width="item.prop === 'goods_name' ? 200 : ''"
          >
            <template v-slot="scope">
              <template v-if="item.isImg">
                <el-image
                  :src="scope.row[item.prop]"
                  :preview-src-list="[scope.row[item.prop]]"
                >
                  <div slot="error" class="image-slot">
                    <img src="../../../../assets/images/no_img.png" />
                  </div>
                </el-image>
              </template>
              <template v-else-if="item.prop === 'goods_name'">
                <div style="display: flex; align-items: center; justify-content: center;">
                  <!-- <span :class="scope.row.goods_name === tableData.list[0].goods_name? '': 'red'">{{ scope.row.goods_name }}</span>
                   -->
                   <span v-if="scope.row.index % 2">{{
                      scope.row[item.prop] || '--'
                    }}</span>
                    <span
                      v-else
                      :class="
                        scope.row[item.prop] ===
                        tableData.list[scope.row.index - 2][item.prop]
                          ? ''
                          : scope.row.label === '修改'
                          ? 'red'
                          : ''
                      "
                    >
                      {{ scope.row[item.prop] || '--'}}
                    </span>
                  <template v-for="item in scope.row.goods_tag_pic">
                    <img
                      :src="item"
                      :key="item"
                      style="margin-left: 5px; height: 15px"
                    />
                  </template>
                </div>
              </template>
              <template v-else>
                <span v-if="scope.row.index % 2">{{
                  scope.row[item.prop] || '--'
                }}</span>
                <span
                  v-else
                  :class="
                    scope.row[item.prop] ===
                    tableData.list[scope.row.index - 2][item.prop]
                      ? ''
                      : scope.row.label === '修改'
                      ? 'red'
                      : ''
                  "
                >
                  {{ scope.row[item.prop] || '--'}}
                </span>
              </template>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <div class="pagina">
      <el-pagination
        :current-page="page"
        background
        @size-change="handleSizeChange"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.total"
        :page-size="$store.state.pageSizes[0]"
        :page-sizes="$store.state.pageSizes"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
    <div style="padding: 20px; display: flex; margin-bottom: 80px;">
      <div style="width: 50px;">备注：</div>
      <div style="flex: 1;">{{this.tableData.remark || '--'}}</div>
    </div>
    <el-dialog
      title="请输入原因"
      :visible.sync="reasonVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-input
        type="textarea"
        :autosize="{ minRows: 4, maxRows: 4 }"
        v-model="audit_remark"
        placeholder="请输入原因"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleReason" :loading="loading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <div class="btns">
      <el-button
        type="primary"
        style="margin-right: 20px"
        @click="toPass"
        :loading="loading"
      >
        审核通过
      </el-button>
      <el-button type="primary" plain @click="toFail" :loading="loading"
        >审核不通过</el-button
      >
    </div>
  </div>
</template>

<script>
import storage from "good-storage";
import { detailGoodsListReq } from "@/api/goods/goodsWarehousing/modifyList/detail";
import { auditReq } from "@/api/goods/goodsWarehousing/modifyList/examine";
import { getAttrList } from "@/api/goods/goodsWarehousing/warehousing";

export default {
  name: "examine",
  data() {
    return {
      page: 1,
      page_num: this.$store.state.pageSizes[0],
      audit_remark: "",
      reasonVisible: false,
      warehouseList: [],
      form: {},
      dialogVisible: false,
      loading: false,
      userInfo: {},
      tableData: {},
      rowList: [],
    };
  },
  props: {
    edit_id: Number,
    goods_type_id: Number,
  },
  created() {
    const userInfo = storage.get("userInfo");
    this.userInfo = userInfo;
    this.handleArrList();
    this.getDetailGoods();
  },
  methods: {
    handleCurrentChange(page) {
      this.page = page;
      this.getDetailGoods();
    },
    // 改变分页条数
    handleSizeChange(page_num) {
      this.page_num = page_num;
      this.getDetailGoods();
    },
    handleClose() {
      this.reasonVisible = false;
      this.audit_remark = "";
    },
    handleReason() {
      this.audit_remark = this.audit_remark.trim();
      if (!this.audit_remark) {
        this.$message.error("请输入原因");
        return;
      }
      this.handleAudit(30);
    },
    objectSpanMethod({ rowIndex, columnIndex }) {
      for (let i = 0; i < this.rowList.length; i++) {
        const item = this.rowList[i];
        if (item.span) {
          this.border = true;
          if (columnIndex === i) {
            if (rowIndex % item.span === 0) {
              return {
                rowspan: item.span,
                colspan: 1,
              };
            } else {
              return {
                rowspan: 0,
                colspan: 0,
              };
            }
          }
        }
      }
    },
    // 获取列表的列
    handleArrList() {
      getAttrList({ goods_type_id: this.goods_type_id, isEdit: 1 }).then((res) => {
        if (res.code === 1) {
          const newArr = [];
          newArr.push({
            label: '序号',
            prop: 'tabIndex',
            span: 2,
          });
          res.data.forEach((item) => {
            newArr.push({
              label: item.attr_name,
              prop: "" + item.attr_fields,
              hide: item.hide,
              // span: item.attr_fields === "goods_number" ? 2 : 0,
            });
          });
          newArr.splice(1, 0, {
            label: "修改标志",
            prop: "label",
          });
          this.rowList = newArr;
        }
      });
    },
    handleTrue() {
      this.dialogVisible = false;
      this.handleRecipient(40);
    },
    handleAudit(edit_status) {
      this.loading = true;
      auditReq({
        edit_id: this.edit_id,
        edit_status: edit_status,
        audit_remark: this.audit_remark,
      })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            this.$emit("hide");
            this.$emit("getList");
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleRecipient(allot_status) {
      this.loading = true;
      recipientReq({
        edit_id: this.edit_id,
        allot_status: allot_status,
        warehouse_id: this.form.warehouse_id,
      })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            this.$emit("hide");
            this.$emit("getList");
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    toPass() {
      this.$confirm("确定要审核通过吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        // 审核通过
        this.handleAudit(20);
      });
    },
    toFail() {
      this.reasonVisible = true;
      // this.$confirm('确定要审核不通过吗?', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning',
      // }).then(() => {
      //   // 审核不通过
      //   this.handleAudit(30);
      // });
    },
    handleRow({ row, rowIndex }) {
      row.index = rowIndex + 1;
    },
    getDetailGoods() {
      this.loading = true;
      detailGoodsListReq({ edit_id: this.edit_id, page: this.page, page_num: this.page_num })
        .then((res) => {
          if (res.code === 1) {
            const newArr = [];
            res.data.list.forEach((item, index) => {
              this.loading = false;
              if (item.goods_info) {
                newArr.push({
                  ...item.goods_info.old_info,
                  tabIndex: index + 1,
                  label: "原始",
                  edit_id: item.edit_id,
                  goods_id: item.goods_id,
                  goods_info: item.goods_info,
                  // goods_number: item.goods_number,
                  goods_tag_pic: item.goods_tag_pic,
                });
                newArr.push({
                  ...item.goods_info.new_info,
                  tabIndex: index + 1,
                  label: "修改",
                  edit_id: item.edit_id,
                  goods_id: item.goods_id,
                  goods_info: item.goods_info,
                  // goods_number: item.goods_number,
                  goods_tag_pic: item.goods_tag_pic,
                });
              }
            });
            this.tableData = {
              list: newArr,
              total: res.data.total,
              remark: res.data.remark,
            };
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    goBack() {
      this.$emit("hide");
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .red {
  color: #fd563a;
}
/deep/ .image-slot {
  width: 80px;
  height: 80px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 10px;
  }
}
/deep/ .el-image {
  img {
    object-fit: cover;
    margin: 10px;
  }
}
.btns {
  padding: 20px;
  background: white;
  margin-top: 20px;
  position: fixed;
  left: 84px;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
}
</style>
